import React from 'react'
import UsageFrequencies from './components/UsageFrequencies'

function index() {
  return (
    <>
      <UsageFrequencies />
    </>
  )
}

export default index
