import i18n from '../locales'
      function strings(){
    return{
        btn__add: i18n.t("btn.add", { defaultValue: "" }),
        btn__add_new: i18n.t("btn.add_new", { defaultValue: "" }),
        btn__edit: i18n.t("btn.edit", { defaultValue: "" }),
        btn__delete: i18n.t("btn.delete", { defaultValue: "" }),
        btn__login: i18n.t("btn.login", { defaultValue: "" }),
        btn__logout: i18n.t("btn.logout", { defaultValue: "" }),
        btn__cancel: i18n.t("btn.cancel", { defaultValue: "" }),
        btn__save: i18n.t("btn.save", { defaultValue: "" }),
        api__fail_request: i18n.t("api.fail_request", { defaultValue: "" }),
        api__error_network: i18n.t("api.error_network", { defaultValue: "" }),
        menu__home: i18n.t("menu.home", { defaultValue: "" }),
        menu__customer: i18n.t("menu.customer", { defaultValue: "" }),
        menu__account: i18n.t("menu.account", { defaultValue: "" }),
        menu__stalls_list: i18n.t("menu.stalls_list", { defaultValue: "" }),
        menu__event_list: i18n.t("menu.event_list", { defaultValue: "" }),
        menu__staff: i18n.t("menu.staff", { defaultValue: "" }),
        menu__news: i18n.t("menu.news", { defaultValue: "" }),
        menu__event: i18n.t("menu.event", { defaultValue: "" }),
        menu__category: i18n.t("menu.category", { defaultValue: "" }),
        menu__gift_and_voucher: i18n.t("menu.gift_and_voucher", { defaultValue: "" }),
        menu__gift: i18n.t("menu.gift", { defaultValue: "" }),
        menu__voucher: i18n.t("menu.voucher", { defaultValue: "" }),
        menu__statistical: i18n.t("menu.statistical", { defaultValue: "" }),
        menu__online_frequency: i18n.t("menu.online_frequency", { defaultValue: "" }),
        menu__count_customer_event: i18n.t("menu.count_customer_event", { defaultValue: "" }),
        menu__stalls_infomation: i18n.t("menu.stalls_infomation", { defaultValue: "" }),
        menu__customer_infomation: i18n.t("menu.customer_infomation", { defaultValue: "" }),
        menu__survey_sheet: i18n.t("menu.survey_sheet", { defaultValue: "" }),
        menu__contact: i18n.t("menu.contact", { defaultValue: "" }),
        menu__setting: i18n.t("menu.setting", { defaultValue: "" }),
        login__save_info_login: i18n.t("login.save_info_login", { defaultValue: "" }),
        login__placeholder__user: i18n.t("login.placeholder.user", { defaultValue: "" }),
        login__placeholder__password: i18n.t("login.placeholder.password", { defaultValue: "" }),
        login__warning__user: i18n.t("login.warning.user", { defaultValue: "" }),
        login__warning__password: i18n.t("login.warning.password", { defaultValue: "" }),
        upload__image__error__size: i18n.t("upload.image.error.size", { defaultValue: "" }),
        upload__image__warning__size: i18n.t("upload.image.warning.size", { defaultValue: "" }),
        account__title__add: i18n.t("account.title.add", { defaultValue: "" }),
        account__title__edit: i18n.t("account.title.edit", { defaultValue: "" }),
        account__title__delete: i18n.t("account.title.delete", { defaultValue: "" }),
        account__title__confirm_delete: i18n.t("account.title.confirm_delete", { defaultValue: "" }),
        account__title__success_delete: i18n.t("account.title.success_delete", { defaultValue: "" }),
        account__title__success_change_status: i18n.t("account.title.success_change_status", { defaultValue: "" }),
        account_and_customer__title__name: i18n.t("account_and_customer.title.name", { defaultValue: "" }),
        account_and_customer__title__phone_number: i18n.t("account_and_customer.title.phone_number", { defaultValue: "" }),
        account_and_customer__title__email: i18n.t("account_and_customer.title.email", { defaultValue: "" }),
        account_and_customer__title__position: i18n.t("account_and_customer.title.position", { defaultValue: "" }),
        account_and_customer__title__password: i18n.t("account_and_customer.title.password", { defaultValue: "" }),
        account_and_customer__title__confirm_password: i18n.t("account_and_customer.title.confirm_password", { defaultValue: "" }),
        account_and_customer__title__type_account: i18n.t("account_and_customer.title.type_account", { defaultValue: "" }),
        account_and_customer__title__create_date: i18n.t("account_and_customer.title.create_date", { defaultValue: "" }),
        account_and_customer__title__status: i18n.t("account_and_customer.title.status", { defaultValue: "" }),
        account_and_customer__placeholder__name: i18n.t("account_and_customer.placeholder.name", { defaultValue: "" }),
        account_and_customer__placeholder__phone_number: i18n.t("account_and_customer.placeholder.phone_number", { defaultValue: "" }),
        account_and_customer__placeholder__email: i18n.t("account_and_customer.placeholder.email", { defaultValue: "" }),
        account_and_customer__placeholder__type_account: i18n.t("account_and_customer.placeholder.type_account", { defaultValue: "" }),
        account_and_customer__placeholder__password: i18n.t("account_and_customer.placeholder.password", { defaultValue: "" }),
        account_and_customer__warring__name: i18n.t("account_and_customer.warring.name", { defaultValue: "" }),
        account_and_customer__warring__phone_number: i18n.t("account_and_customer.warring.phone_number", { defaultValue: "" }),
        account_and_customer__warring__email: i18n.t("account_and_customer.warring.email", { defaultValue: "" }),
        account_and_customer__warring__position: i18n.t("account_and_customer.warring.position", { defaultValue: "" }),
        account_and_customer__warring__password: i18n.t("account_and_customer.warring.password", { defaultValue: "" }),
        account_and_customer__warring__confirm_password: i18n.t("account_and_customer.warring.confirm_password", { defaultValue: "" }),
        account_and_customer__filter__title__search: i18n.t("account_and_customer.filter.title.search", { defaultValue: "" }),
        account_and_customer__filter__title__status: i18n.t("account_and_customer.filter.title.status", { defaultValue: "" }),
        account_and_customer__filter__title__type_account: i18n.t("account_and_customer.filter.title.type_account", { defaultValue: "" }),
        detail_customer__page_header__customer_detail: i18n.t("detail_customer.page_header.customer_detail", { defaultValue: "" }),
        detail_customer__title__customer_info: i18n.t("detail_customer.title.customer_info", { defaultValue: "" }),
        detail_customer__title__history_of_point: i18n.t("detail_customer.title.history_of_point", { defaultValue: "" }),
        detail_customer__title__history_Exchange_points: i18n.t("detail_customer.title.history_Exchange_points", { defaultValue: "" }),
        detail_customer__title__name: i18n.t("detail_customer.title.name", { defaultValue: "" }),
        detail_customer__title__phone: i18n.t("detail_customer.title.phone", { defaultValue: "" }),
        detail_customer__title__id_card: i18n.t("detail_customer.title.id_card", { defaultValue: "" }),
        detail_customer__title__province: i18n.t("detail_customer.title.province", { defaultValue: "" }),
        detail_customer__title__district: i18n.t("detail_customer.title.district", { defaultValue: "" }),
        detail_customer__title__wards: i18n.t("detail_customer.title.wards", { defaultValue: "" }),
        detail_customer__table__event: i18n.t("detail_customer.table.event", { defaultValue: "" }),
        detail_customer__table__staff: i18n.t("detail_customer.table.staff", { defaultValue: "" }),
        detail_customer__table__count_point: i18n.t("detail_customer.table.count_point", { defaultValue: "" }),
        detail_customer__table__single_number: i18n.t("detail_customer.table.single_number", { defaultValue: "" }),
        detail_customer__table_child__receipt_number: i18n.t("detail_customer.table_child.receipt_number", { defaultValue: "" }),
        detail_customer__table_child__stalls_name: i18n.t("detail_customer.table_child.stalls_name", { defaultValue: "" }),
        detail_customer__table_child__total_price: i18n.t("detail_customer.table_child.total_price", { defaultValue: "" }),
        detail_customer__table_child__image: i18n.t("detail_customer.table_child.image", { defaultValue: "" }),
        voucher_and_gif__table__code: i18n.t("voucher_and_gif.table.code", { defaultValue: "" }),
        voucher_and_gif__table__name: i18n.t("voucher_and_gif.table.name", { defaultValue: "" }),
        voucher_and_gif__table__point_swap: i18n.t("voucher_and_gif.table.point_swap", { defaultValue: "" }),
        voucher_and_gif__table__type_swap: i18n.t("voucher_and_gif.table.type_swap", { defaultValue: "" }),
        voucher_and_gif__table__quantity: i18n.t("voucher_and_gif.table.quantity", { defaultValue: "" }),
        voucher_and_gif__table__create_date: i18n.t("voucher_and_gif.table.create_date", { defaultValue: "" }),
        voucher_and_gif__table__status: i18n.t("voucher_and_gif.table.status", { defaultValue: "" }),
        voucher_and_gif__edit: i18n.t("voucher_and_gif.edit", { defaultValue: "" }),
        voucher_and_gif__detail: i18n.t("voucher_and_gif.detail", { defaultValue: "" }),
        voucher_and_gif__delete: i18n.t("voucher_and_gif.delete", { defaultValue: "" }),
        voucher_and_gif__confirm__delete: i18n.t("voucher_and_gif.confirm.delete", { defaultValue: "" }),
        stalls__add_stalls: i18n.t("stalls.add_stalls", { defaultValue: "" }),
        stalls__edit_stalls: i18n.t("stalls.edit_stalls", { defaultValue: "" }),
        stalls__delete_stalls: i18n.t("stalls.delete_stalls", { defaultValue: "" }),
        stalls__success__change_status: i18n.t("stalls.success.change_status", { defaultValue: "" }),
        stalls__placeholder_search: i18n.t("stalls.placeholder_search", { defaultValue: "" }),
        stalls__placeholder_type_stalls: i18n.t("stalls.placeholder_type_stalls", { defaultValue: "" }),
        stalls__placeholder_status: i18n.t("stalls.placeholder_status", { defaultValue: "" }),
        stalls__placeholder_floor: i18n.t("stalls.placeholder_floor", { defaultValue: "" }),
        stalls__table__stalls_code: i18n.t("stalls.table.stalls_code", { defaultValue: "" }),
        stalls__table__stalls_name: i18n.t("stalls.table.stalls_name", { defaultValue: "" }),
        stalls__table__stalls_position: i18n.t("stalls.table.stalls_position", { defaultValue: "" }),
        stalls__table__stalls_contact: i18n.t("stalls.table.stalls_contact", { defaultValue: "" }),
        stalls__table__stalls_type: i18n.t("stalls.table.stalls_type", { defaultValue: "" }),
        stalls__table__stalls_status: i18n.t("stalls.table.stalls_status", { defaultValue: "" }),
        stalls__add_edit__title__group_contact: i18n.t("stalls.add_edit.title.group_contact", { defaultValue: "" }),
        stalls__add_edit__title__name: i18n.t("stalls.add_edit.title.name", { defaultValue: "" }),
        stalls__add_edit__title__industry: i18n.t("stalls.add_edit.title.industry", { defaultValue: "" }),
        stalls__add_edit__title__position: i18n.t("stalls.add_edit.title.position", { defaultValue: "" }),
        stalls__add_edit__title__promotion_event: i18n.t("stalls.add_edit.title.promotion_event", { defaultValue: "" }),
        stalls__add_edit__title__hotline: i18n.t("stalls.add_edit.title.hotline", { defaultValue: "" }),
        stalls__add_edit__title__Facebook: i18n.t("stalls.add_edit.title.Facebook", { defaultValue: "" }),
        stalls__add_edit__title__website: i18n.t("stalls.add_edit.title.website", { defaultValue: "" }),
        stalls__add_edit__title__image: i18n.t("stalls.add_edit.title.image", { defaultValue: "" }),
        stalls__add_edit__title__desc: i18n.t("stalls.add_edit.title.desc", { defaultValue: "" }),
        stalls__add_edit__title__code: i18n.t("stalls.add_edit.title.code", { defaultValue: "" }),
        stalls__add_edit__placeholder__name: i18n.t("stalls.add_edit.placeholder.name", { defaultValue: "" }),
        stalls__add_edit__placeholder__industry: i18n.t("stalls.add_edit.placeholder.industry", { defaultValue: "" }),
        stalls__add_edit__placeholder__position: i18n.t("stalls.add_edit.placeholder.position", { defaultValue: "" }),
        stalls__add_edit__placeholder__promotion_event: i18n.t("stalls.add_edit.placeholder.promotion_event", { defaultValue: "" }),
        stalls__add_edit__placeholder__hotline: i18n.t("stalls.add_edit.placeholder.hotline", { defaultValue: "" }),
        stalls__add_edit__placeholder__Facebook: i18n.t("stalls.add_edit.placeholder.Facebook", { defaultValue: "" }),
        stalls__add_edit__placeholder__website: i18n.t("stalls.add_edit.placeholder.website", { defaultValue: "" }),
        stalls__add_edit__warning__name: i18n.t("stalls.add_edit.warning.name", { defaultValue: "" }),
        stalls__add_edit__warning__position: i18n.t("stalls.add_edit.warning.position", { defaultValue: "" }),
        stalls__add_edit__warning__industry: i18n.t("stalls.add_edit.warning.industry", { defaultValue: "" }),
        stalls__add_edit__warning__image: i18n.t("stalls.add_edit.warning.image", { defaultValue: "" }),
        stalls__add_edit__warning__image_reg: i18n.t("stalls.add_edit.warning.image_reg", { defaultValue: "" }),
        stalls__page_header__add: i18n.t("stalls.page_header.add", { defaultValue: "" }),
        stalls__page_header__edit: i18n.t("stalls.page_header.edit", { defaultValue: "" }),
        stalls__confirm_delete: i18n.t("stalls.confirm_delete", { defaultValue: "" }),
        stalls__add_success: i18n.t("stalls.add_success", { defaultValue: "" }),
        stalls__edit_success: i18n.t("stalls.edit_success", { defaultValue: "" }),
        stalls__delete_success: i18n.t("stalls.delete_success", { defaultValue: "" }),
        stalls__add_error: i18n.t("stalls.add_error", { defaultValue: "" }),
        stalls__delete_error: i18n.t("stalls.delete_error", { defaultValue: "" }),
        news__page_header__add: i18n.t("news.page_header.add", { defaultValue: "" }),
        news__page_header__edit: i18n.t("news.page_header.edit", { defaultValue: "" }),
        news__btn__add_new: i18n.t("news.btn.add_new", { defaultValue: "" }),
        news__filter__search: i18n.t("news.filter.search", { defaultValue: "" }),
        news__filter__news_type: i18n.t("news.filter.news_type", { defaultValue: "" }),
        news__filter__status: i18n.t("news.filter.status", { defaultValue: "" }),
        news__add_edit__label__name: i18n.t("news.add_edit.label.name", { defaultValue: "" }),
        news__add_edit__label__post_status: i18n.t("news.add_edit.label.post_status", { defaultValue: "" }),
        news__add_edit__label__type_news: i18n.t("news.add_edit.label.type_news", { defaultValue: "" }),
        news__add_edit__label__send_notification: i18n.t("news.add_edit.label.send_notification", { defaultValue: "" }),
        news__add_edit__label__image: i18n.t("news.add_edit.label.image", { defaultValue: "" }),
        news__add_edit__label__content: i18n.t("news.add_edit.label.content", { defaultValue: "" }),
        news__add_edit__label__related_stalls: i18n.t("news.add_edit.label.related_stalls", { defaultValue: "" }),
        news__add_edit__warning__name: i18n.t("news.add_edit.warning.name", { defaultValue: "" }),
        news__add_edit__warning__post_status: i18n.t("news.add_edit.warning.post_status", { defaultValue: "" }),
        news__add_edit__warning__type_news: i18n.t("news.add_edit.warning.type_news", { defaultValue: "" }),
        news__add_edit__warning__image: i18n.t("news.add_edit.warning.image", { defaultValue: "" }),
        news__add_edit__warning__content: i18n.t("news.add_edit.warning.content", { defaultValue: "" }),
        news__add_edit__warning__related_stalls: i18n.t("news.add_edit.warning.related_stalls", { defaultValue: "" }),
        news__add_edit__success__delete_news: i18n.t("news.add_edit.success.delete_news", { defaultValue: "" }),
        news__add_edit__placeholder__name: i18n.t("news.add_edit.placeholder.name", { defaultValue: "" }),
        news__add_edit__placeholder__post_status: i18n.t("news.add_edit.placeholder.post_status", { defaultValue: "" }),
        news__add_edit__placeholder__related_stalls: i18n.t("news.add_edit.placeholder.related_stalls", { defaultValue: "" }),
        news__add_edit__placeholder__type_news: i18n.t("news.add_edit.placeholder.type_news", { defaultValue: "" }),
        news__table__news_id: i18n.t("news.table.news_id", { defaultValue: "" }),
        news__table__news_type: i18n.t("news.table.news_type", { defaultValue: "" }),
        news__table__news_title: i18n.t("news.table.news_title", { defaultValue: "" }),
        news__table__news_post_status: i18n.t("news.table.news_post_status", { defaultValue: "" }),
        news__table__news_create_date: i18n.t("news.table.news_create_date", { defaultValue: "" }),
        news__table__news_status: i18n.t("news.table.news_status", { defaultValue: "" })
}}
export default strings
        