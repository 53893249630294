import ICONS from './icons'
import IMAGES from './images'
import strings from './strings'
import { logos } from './logo'

const R = {
  images: IMAGES,
  icons: ICONS,
  logos: logos,
  strings: strings,
}

export default R
