import Staffs from './components/Staffs'

const Staff = () => {
  return (
    <>
      <Staffs />
    </>
  )
}

export default Staff
