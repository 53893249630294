import React from 'react'
import CountCustomers from './components/CountCustomers'

function index() {
  return (
    <div>
      <CountCustomers />
    </div>
  )
}

export default index
