import React from 'react'
import Events from './components/Events'

function index() {
  return (
    <>
      <Events />
    </>
  )
}

export default index
